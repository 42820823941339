import React from 'react'
import Layout from './components/Layout'
import {Base64, CpfCnpj, UrlEncode, MeuIP, SqlFormater} from './pages'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

export default () => (
  <Router>
    <Layout>
      <Switch>
        <Route exact path="/">
            <Base64/>
        </Route>
        <Route path="/base64">
            <Base64/>
        </Route>
        <Route path="/cpfcnpj">
            <CpfCnpj/>
        </Route>
        <Route path="/urlencode">
            <UrlEncode/>
        </Route>
        <Route path="/meuip">
            <MeuIP/>
        </Route>
        <Route path="/sqlformater">
            <SqlFormater/>
        </Route>
      </Switch>
    </Layout>
  </Router>
)