import React, { Component } from 'react';
import { Container, Row, Navbar } from 'react-bootstrap';
import NavMenu from './NavMenu';

class Layout extends Component {

    render() {
        return (
            <React.Fragment>
                <Navbar className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
                    <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="/">Tools by TK</a>
                    {/*<ul className="navbar-nav px-3">
                        <li className="nav-item text-nowrap">
                            <a className="nav-link">Sair</a>
                        </li>
                    </ul>*/}
                </Navbar>
                <Container fluid>
                    <Row>
                        {<NavMenu />}
                        <main className="col-md-9 ml-sm-auto col-lg-10 px-4">
                            {this.props.children}
                        </main>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Layout