import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { FaChartLine, FaBook, FaFileImport } from 'react-icons/fa'
import { Link } from "react-router-dom"

export default props => (
    <Navbar className="sidebar col-md-2 d-none d-md-block bg-light">
        <Nav className="flex-column">

            <h5 className='sidebar-heading nav-link'>
                <FaBook /> Generators
            </h5>

            <Link className='nav-link' to={'/cpfcnpj'}>
                CPF/CNPJ
            </Link>

            <h5 className='sidebar-heading nav-link'>
                <FaFileImport /> Formatter
            </h5>

            <Link className='nav-link' to={'/meuip'}>
                Meu IP
            </Link>

            <Link className='nav-link' to={'/base64'}>
                Base64
            </Link>

            <Link className='nav-link' to={'/urlencode'}>
                Url Encode
            </Link>

            <Link className='nav-link' to={'/sqlformater'}>
                SQL Format
            </Link>
        </Nav>
    </Navbar>
);