import React, {PureComponent} from 'react'
import {Form, Button} from 'react-bootstrap'
import {BasePage} from '../../components'

class CpfCnpj extends PureComponent {

    constructor(props){
        super(props)

        this.state = {
            cpf: '',
            cpfSemMascara: '',
        }
    }

    componentDidMount(){
        this.onClickCNPJ();
        this.onClickCPF();
    }

    onClickCPF = () => {
        const cpf = this.gerarCPF();

        this.setState({
            cpf,
            cpfSemMascara: cpf.match(/[0-9]/g).join('')
        })
    }

    onClickCNPJ = () => {
        const cnpj = this.gerarCNPJ();

        this.setState({
            cnpj,
            cnpjSemMascara: cnpj.match(/[0-9]/g).join('')
        })
    }

    gerarCPF = () => {
        const num1 = this.aleatorio(); //aleatorio já devolve string, logo não precisa de toString
        const num2 = this.aleatorio();
        const num3 = this.aleatorio();
        
        const dig1 = this.dig(num1, num2, num3); //agora só uma função dig
        const dig2 = this.dig(num1, num2, num3, dig1); //mesma função dig aqui
        
        //aqui com interpolação de strings fica bem mais legivel
        return `${num1}.${num2}.${num3}-${dig1}${dig2}`;
    }
      
      //o quarto parametro(n4) só será recebido para o segundo digito
    dig = (n1, n2, n3, n4) => { 
        //as concatenações todas juntas uma vez que são curtas e legíveis
        let nums = n1.split("").concat(n2.split(""), n3.split(""));
        
        if (n4){ //se for o segundo digito coloca o n4 no sitio certo
            nums[9] = n4;
        }
        
        let x = 0;
            
        //o j é também iniciado e incrementado no for para aproveitar a própria sintaxe dele
        //o i tem inicios diferentes consoante é 1º ou 2º digito verificador
        for (let i = (n4 ? 11:10), j = 0; i >= 2; i--, j++) {
            x += parseInt(nums[j]) * i;
        }
        
        const y = x % 11;
        //ternário aqui pois ambos os retornos são simples e continua legivel
        return y < 2 ? 0 : 11 - y; 
    }
      
    aleatorio = () => {
        const aleat = Math.floor(Math.random() * 999);
        //o preenchimento dos zeros à esquerda é mais facil com a função padStart da string
        return ("" + aleat).padStart(3, '0'); 
    }
    
    rnd = (n) => {
        return Math.round(Math.random()*n);;
    }

    mod = (dividendo,divisor) => {
        return Math.round(dividendo - (Math.floor(dividendo/divisor)*divisor));
    }

    gerarCNPJ = () => {
        var n = 9;
        var n1 = this.rnd(n);
        var n2 = this.rnd(n);
        var n3 = this.rnd(n);
        var n4 = this.rnd(n);
        var n5 = this.rnd(n);
        var n6 = this.rnd(n);
        var n7 = this.rnd(n);
        var n8 = this.rnd(n);
        var n9 = 0;
        var n10 = 0;
        var n11 = 0;
        var n12 = 1;
        var d1 = n12*2+n11*3+n10*4+n9*5+n8*6+n7*7+n6*8+n5*9+n4*2+n3*3+n2*4+n1*5;
        d1 = 11 - ( this.mod(d1,11) );
        if (d1>=10) d1 = 0;
        var d2 = d1*2+n12*3+n11*4+n10*5+n9*6+n8*7+n7*8+n6*9+n5*2+n4*3+n3*4+n2*5+n1*6;
        d2 = 11 - ( this.mod(d2,11) );
        if (d2>=10) d2 = 0;

        return ''+n1+n2+'.'+n3+n4+n5+'.'+n6+n7+n8+'/'+n9+n10+n11+n12+'-'+d1+d2;;
    }

    render(){
        return(
            <BasePage>
                <h1>Generate CPF / CNPJ</h1>
                <Form>
                    <Form.Group>
                        <div>
                            <Button onClick={this.onClickCPF}>Generate CPF</Button>
                        </div>
                        <br/>
                        <div>
                            <Form.Control readOnly type="text" value={this.state.cpf} />
                            <Form.Control readOnly type="text" value={this.state.cpfSemMascara} />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <div>
                            <Button onClick={this.onClickCNPJ}>Generate CNPJ</Button>
                        </div>
                        <br/>
                        <div>
                            <Form.Control readOnly type="text" value={this.state.cnpj} />
                            <Form.Control readOnly type="text" value={this.state.cnpjSemMascara} />
                        </div>
                    </Form.Group>
                </Form>
            </BasePage>
        )
    }   
}

export default CpfCnpj