import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'
import {BasePage} from '../../components'

class UrlEncode extends PureComponent {

    constructor(props){
        super(props)

        this.state = {
            origem: '',
            destino: '',
        }
    }

    onChangeOrigem = (e) => {
        this.setState({
            origem: e.target.value
        })
    }

    onChangeDestino = (e) => {
        this.setState({
            destino: e.target.value
        })
    }

    onClickConvertFrom = () => {
        this.setState({
            destino: encodeURI(this.state.origem)
        })
    }

    onClickConvertTo = () => {
        this.setState({
            origem: decodeURI(this.state.destino)
        })
    }

    render(){

        return(
            <BasePage>
                <h1>Encode / Decode URL</h1>
                <div>
                    <div>From</div>
                    <textarea
                        rows={10}
                        cols={70}
                        value={this.state.origem}
                        onChange={this.onChangeOrigem}
                    />
                </div>
                <div>
                    <Button onClick={this.onClickConvertFrom}>Encode (From > To)</Button>
                    <Button onClick={this.onClickConvertTo}>Decode (To > From)</Button>
                </div>
                <div>
                    <div>To</div>
                    <textarea
                        rows={10}
                        cols={70}
                        value={this.state.destino}
                        onChange={this.onChangeDestino}
                    />
                </div>
            </BasePage>
        )
    }   
}

export default UrlEncode