import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'
import {BasePage} from '../../components'
import {Spinner} from 'react-bootstrap'
import * as ip from 'public-ip'

class MeuIP extends PureComponent {

    constructor(props){
        super(props)
        
        this.state = {
            meuip: '',
        }
    }

    async componentDidMount (){
        const meuip = await ip.v4();

        this.setState({
            meuip
        })
    }

    render(){
        return(
            <BasePage>
                <h1>Meu IP</h1>
                <div>
                    {!this.state.meuip &&
                        <div><Spinner animation="border" /></div>
                    }

                    {this.state.meuip &&
                        <h4>IP: {this.state.meuip}</h4>
                    }
                </div>
            </BasePage>
        )
    }   
}

export default MeuIP