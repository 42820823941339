import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'
import {BasePage} from '../../components'
import vk from 'vkbeautify'

class SqlFormater extends PureComponent {

    constructor(props){
        super(props)
        
        this.state = {
            sql: '',
        }

    }

    onChangeSql = (e) => {
        this.setState({
            sql: e.target.value
        })
    }

    onClickFormat = () => {
        this.setState({
            sql: vk.sql(this.state.sql)
        })
    }

    render(){
        return(
            <BasePage>
                <h1>SQL Format</h1>
                <div>
                    <textarea 
                        rows={10}
                        cols={70}
                        onChange={this.onChangeSql}
                        value={this.state.sql}>
                    </textarea>
                </div>
                <Button onClick={this.onClickFormat}>Format SQL</Button>
            </BasePage>
        )
    }   
}

export default SqlFormater